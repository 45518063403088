// Here you can add other styles
.card-container {
    width: 100%;
    padding: 13px;
}

.card-spinner {
    text-align: center;
}

.separator15 {
    height: 15px;
}

label {
    font-weight: bold;
    margin-top: 5px;
}

.cell-number {
    text-align: right;
}

.red {
    color: red !important;
    border-color: red !important;
}

.green {
    color: green !important;
    border-color: green !important;
}

.expenditure {
    color: #ef0e0e;
}

.revenue {
    color: #138e11;
}

.label {
    font-weight: bold;
    margin-right: 10px;
    margin-left: 5px;
}

.cardBody {
    padding-left: 25px;
    padding-top: 5px;
}

.formGeneric {
    width: 98%;
}

.grid-column-id {
    width: 55px;
}

.grid-column-date {
    width: 65px;
}

.grid-column-id-max {
    width: 77px;
}

.grid-column-short {
    width: 110px;
}
.grid-column-date {
    width: 140px;
}

.grid-column-buttons {
    width: 150px;
}

.grid-column-medium {
    width: 180px;
}

.grid-column-25 {
    width: 25%;
}

.grid-column-35 {
    width: 35%;
}

.grid-column-50 {
    width: 50%;
}

.truncate-135 {
    white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:135px;
}

.truncate-medium {
    white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:180px;
}

.invalid-feedback-select {
    font-size: 80%;
    color: #e55353;
}

.today {
    font-size: 20px;
    color:white;
    background-color: rgb(76, 122, 24);
    padding: 5px;
}

.c-sidebar-form {
    width: 235px !important;
    color: black;
}

.c-sidebar-form label {
  color: white;
}

.iconHighLighted {
  width: 33px;
  color: yellow;
}

.cell-action {
    text-align: center !important;
    width: 35px;
}

.brand {
    font-weight: bold;
    color: #5b92e5 !important;
}

.grid-button {
    margin-left: 10px;
    min-width: 125px !important;
}